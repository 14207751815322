jQuery(function () {
    jQuery(document).on(`start load`, function () {
        jQuery('body').not('.togglePasswordView-loaded').addClass('togglePasswordView-loaded').each(function () {
            jQuery('form').on('click', 'button[data-toggle="password"]', function () {
                let $button = $(this);
                let $input = $button.closest('div.input-group').find('input');
                if ($input !== undefined) {
                    let inputType = $input.attr('type');
                    let $iconSpan = $button.find('span.icon');
                    $button.removeClass('LoadSvgFromBackground');
                    $iconSpan.html('');
                    if (inputType === 'password') {
                        $input.prop('type', 'text');
                        $iconSpan.removeClass('icon-view');
                        $iconSpan.removeClass('icon-replaced');
                        $iconSpan.addClass('icon-menu-rolle-berechtigung');
                        if ($button.hasClass('btn-outline-default')) {
                            $button.removeClass('btn-outline-default');
                            $button.addClass('btn-default');
                        }
                    }
                    else {
                        $input.prop('type', 'password');
                        $iconSpan.removeClass('icon-menu-rolle-berechtigung');
                        $iconSpan.removeClass('icon-replaced');
                        $iconSpan.addClass('icon-view');
                        if ($button.hasClass('btn-default')) {
                            $button.removeClass('btn-default');
                            $button.addClass('btn-outline-default');
                        }
                    }
                    jQuery('body').trigger('load');
                }
            });
        });
    }).trigger('start');
});